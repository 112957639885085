.header-default {
    border-bottom:1px solid #212062;
}

.header-dark, .header-default {
    height: 48px;
    background-color: #000000;
}
  
.header-default .logo-wrap, .header-dark .logo-wrap {
    margin-top: 14px;
} 