.page-view.auth-success {
    background: url('../../../assets/device.png') no-repeat;
}
  
.code-view {
    background: #18181B;
    border-radius: 0.5rem;
}

a {
    color: #ffffff!important;
    text-decoration: none!important;
}

a:hover {
    text-decoration: underline!important;
    color: #ffffff;
}