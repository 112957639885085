.page-view.witness-replay {
    background: none;
    margin-top: 0!important;
}

.player-wrap.witness-replay {
    padding: 5px 5px 0 5px;
    margin-bottom: 0!important;
    background: none!important;
}

.side-bar {
    background: #2A2A2E;
    height: 100%;
}

.img-fluid {
    border-radius: 8px;
}

.video-player {
    border-radius: 8px;
}

.view-box {
    position: relative;
    border-radius: 8px;
}

.view-box a {
    display: block;
    cursor: pointer;
}

.dim-box {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 25%;
    z-index: 9999;
    display: flex;
    flex-flow: column;
}

.finished-header {
    font-weight: bold;
}

.finished-header-container {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;
    justify-content: center;
}

.finished-text-container {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-start;
    justify-content: center;
}

.finished-text {
    text-align: center;
    display: inline;
}

.finished-text a {
    display: inline;
    text-decoration: underline;
    font-weight: bold;
    color: #ffffff;
}

.loadingSpinner {
    animation: spin .5s infinite linear;
}

.not-avail {
    height: 208px;
    width: 100%;
    background: #42424A;
    padding: 1px 10px;
    border-radius: 8px;
}

.not-avail h6 {
    text-transform: uppercase;
}

video {
    width: 100% !important;
    height: auto !important;
}

.no-video {
    height: 475px;
}

.no-video h6 {
    text-transform: uppercase;
}

.video-player-controls {
    align-items: center;
}

@media (max-width: 767.98px) {
    .no-video {
        height: 300px;
    }
}

