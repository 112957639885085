.page-view.video {
    background: none;
}

.player-wrap {
    background: #323232;
    padding:5px;
}

.side-bar {
    background: #323232;
}

.view-box a{
    display:block;
    cursor:pointer;
}

.not-avail {
    height: 208px;
    width: 100%;
    background: #42424A;
    padding: 1px 10px;
}

.not-avail h6 {
    text-transform:uppercase;
}

video {
    width: 100% !important;
    height: auto !important;
}

.no-video {
    height: 475px;
}

.no-video h6 {
    text-transform: uppercase;
}

@media (max-width: 767.98px) {
    .no-video {
        height: 300px;
    }
}