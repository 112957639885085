@import url('https://fonts.googleapis.com/css2?family=Poppins');
html, body{min-height:100%;}
body{height:100vh;}

body {
    font-family: Poppins, "Segoe UI", Roboto, Helvetica, Arial, sans-serif!important;
    color:#ffffff!important;
}

body {
  background: linear-gradient(120deg, #043367, #09141E);
}

body.dark-body {
  background: #18181B;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0!important;
}

p {
  margin: 0;
} 

.page-content {
    min-height: 800px;
    width:100%;
}

footer {
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  display: block;
  width: 100%;
  padding-bottom: 10px;
}

footer a {
    color: #ffffff;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
    color: #ffffff;
}

footer span {
    padding: 0 8px;
    font-size: 6px;
    position: relative;
    top: -2px;
}

.page-view {
  min-height: 800px;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-height: 600px) {
  footer { 
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1023.98px) {
  .row {
    flex-wrap: unset!important;
    flex-flow: column;
    align-items: center;
  }

  .front-stream-viewer {
    margin-bottom: 1.25rem;
  }

  .side-bar {
    border-radius: 8px;
  }

  .col-md-4 {
    width: 66.66%!important;
  }

  footer {
    position: relative!important;
    margin-top:40px;
  }
}

@media (max-width: 349.98px) {
  vg-volume {
    display: none!important;
  }
}

@media (max-width: 767.98px) {
  .col-md-4 {
    width: 100%!important;
  }

  .play, .pause {
    font-size: medium!important;
  }

  vg-time-display {
    font-size: small;
    width: 40px!important;
  }

  .time-seperator {
    font-size: medium!important;
  }

  .video-controls {
    padding: 10px 0 5px 0!important;
  }

  vg-mute, vg-mute .icon, vg-fullscreen .icon {
    width: 40px!important;
    font-size: large;
  }
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}