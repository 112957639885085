.code-input {
    flex: 1;
    margin: 0 6px;
    border-radius: 0.375rem!important;
    background-color: #42424A!important;
    border: 1px solid rgba(0, 0, 0, 0.3)!important;
    caret-color: white;
    color: white!important;
    font-family: Poppins, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-size: 30px!important;
    line-height: 30px!important;
    text-transform: uppercase;
    text-align: center;
}

.code-input:focus {
    background-color: #000000!important;
    border-color: #0085EB!important;
    box-shadow:none!important;
}

.code-input.error {
    border-color: #D83B3B!important;
    background-color: #000000!important;
}

.invalid-code {
    color: #D83B3B;
}

.btn-submit {
    padding-left: 25px!important;
    padding-right: 25px!important;
    min-width: 100px;
}